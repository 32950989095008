import React, {createContext, useContext, useEffect, useState} from 'react';
import {TourStepProps} from "antd/es/tour/interface";
import {t} from "i18next";
import {Tour} from "antd";
import {HttpConnection} from "shared/Designer/RequestCreator";
import {TdmApi} from "shared/Api/TdmApi";
import {Size} from "shared/Designer/Size";
import {PageDto} from "shared/Generated/Dto/PageDto";
import {PlaylistDto} from "shared/Generated/Dto/PlaylistDto";
import {ScheduleDto} from "shared/Generated/Dto/Schedule/ScheduleDto";

interface props {
    children: React.ReactNode;
}


type TourType = {
    isShowingTour: boolean
    startTour: (value: boolean) => void;
    setDashboardTargets: (elements: HTMLElement[]) => void;
    setPlayerTargets: (elements: HTMLElement[]) => void;
    setPageTargets: (elements: HTMLElement[]) => void;
    setDesignerTargets: (elements: HTMLElement[]) => void;
    setPlaylistTargets: (elements: HTMLElement[]) => void;
    setPlaylistEditTargets: (elements: HTMLElement[]) => void;
    setMediaBrowserTargets: (elements: HTMLElement[]) => void;
    setScheduleIndexTargets: (elements: HTMLElement[]) => void;
    setScheduleEditTargets: (elements: HTMLElement[]) => void;
    setDataSourceIndexTargets: (elements: HTMLElement[]) => void;
    setSearchTarget: (element: HTMLElement) => void;
    setAccountTarget: (element: HTMLElement) => void;
}

const TourContext = createContext<TourType>({} as TourType);
const TourContextProvider: React.FC<props> = ({children}) => {
    const [dashboardTargets, setDashboardTargets] = useState<HTMLElement[]>();
    const [playerTargets, setPlayerTargets] = useState<HTMLElement[]>();
    const [pageTargets, setPageTargets] = useState<HTMLElement[]>();
    const [designerTargets, setDesignerTargets] = useState<HTMLElement[]>();
    const [playlistTargets, setPlaylistTargets] = useState<HTMLElement[]>();
    const [playlistEditTargets, setPlaylistEditTargets]= useState<HTMLElement[]>();
    const [mediaBrowserTargets, setMediaBrowserTargets] = useState<HTMLElement[]>();
    const [scheduleIndexTargets, setScheduleIndexTargets] = useState<HTMLElement[]>();
    const [scheduleEditTargets, setScheduleEditTargets] = useState<HTMLElement[]>();
    const [dataSourceIndexTargets, setDataSourceIndexTargets]= useState<HTMLElement[]>();
    const [searchTarget, setSearchTarget] = useState<HTMLElement>();
    const [accountTarget, setAccountTarget] = useState<HTMLElement>();
    const [open, setOpen] = useState<boolean>();
    const [steps, setSteps] = useState<TourStepProps[]>();
    
    useEffect(() => {
        if(dashboardTargets)
            setDashboardSteps();
    }, [dashboardTargets]);

    useEffect(() => {
        if(playerTargets)
            setPlayerSteps();
    }, [playerTargets]);

    useEffect(() => {
        if(pageTargets)
            setPageSteps();
    }, [pageTargets]);

    useEffect(() => {
        if(designerTargets)
            setDesignerSteps();
    }, [designerTargets]);

    useEffect(() => {
        if(playlistTargets)
            setPlaylistSteps();
    }, [playlistTargets]);

    useEffect(() => {
        if(playlistEditTargets)
            setPlaylistEditSteps();
    }, [playlistEditTargets]);

    useEffect(() => {
        if(mediaBrowserTargets)
            setMediaBrowserSteps();
    }, [mediaBrowserTargets]);

    useEffect(() => {
        if(scheduleIndexTargets)
            setScheduleIndexSteps();
    }, [scheduleIndexTargets]);

    useEffect(() => {
        if(scheduleEditTargets)
            setScheduleEditSteps();
    }, [scheduleEditTargets]);

    useEffect(() => {
        if(dataSourceIndexTargets)
            setDataSourceIndexSteps();
    }, [dataSourceIndexTargets]);

    useEffect(() => {
        const showTour = localStorage.getItem("ShowTour");
        if(!showTour){ //If no localstorage item is set, start the tour, because it is probably the first time you see the page.
            setOpen(true);
            return;
        }
        setOpen(localStorage.getItem("ShowTour") == "true");
    }, []);
    

    useEffect(() => {
        if(open)
            localStorage.setItem("ShowTour", "true");
        else
            localStorage.setItem("ShowTour", "false");
    }, [open]);

 
    
    useEffect(() => {
        if(searchTarget && accountTarget && dashboardTargets)
            setHeaderSteps();
    }, [searchTarget, accountTarget]);
    const setDashboardSteps = ()  => {
        setSteps(
        [
            {
                title: t("Player status"),
                description: t("From here you can quickly view the current state of each player(s). It also informs you about the current content each player is playing and quickly set content to a player. "),
                target: () => dashboardTargets[0],
            },
            {
                title: t("License overview"),
                description: t("This is an overview of your current license(s) and renewal date. For a more detailed view of the current licenses, managing your license plan, just click the Administration overview button."),
                target: () => dashboardTargets[1],
            },
        ]
        );
    };

    const setHeaderSteps = ()  => {
        setSteps(prev => 
            [
                ...prev,
                {
                    title: t("Search"),
                    description: t("Use the Search bar to quickly to search and access anything (such as a page/playlist or a media item) within your environment."),
                    target: () => searchTarget,
                },
                {
                    title: t("User Dropdown"),
                    description: t("Quickly access your environments, edit your profile and log out."),
                    target: () => accountTarget,
                    onFinish: () => location.href = `/${HttpConnection.environmentPath}/players`,
                    nextButtonProps: {
                        children: t("Go to players"),
                    }
                }
            ]
        );
    };
    
    const setPlayerSteps = () => {
        setSteps(
            [
                {
                    title: t("Add new player"),
                    description: t("Use the Search bar to quickly to search and access anything (such as a page/playlist or a media item) within your environment."),
                    target: () => playerTargets[0],
                },
                {
                    title: t("Add players to group"),
                    description: t("Use the Search bar to quickly to search and access anything (such as a page/playlist or a media item) within your environment."),
                    target: () => playerTargets[1],
                },
                {
                    title: t("Set default content"),
                    description: t("Quickly access your environments, edit your profile and log out."),
                    target: () => playerTargets[2],
                    onFinish: () => location.href = `/${HttpConnection.environmentPath}/pages`,
                    nextButtonProps: {
                        children: t("Go to pages"),
                    }
                }
            ]
        );
    };
    
    
    const setPageSteps = () => {
        setSteps(
            [
                {
                    title: t("Import page"),
                    description: t("Use this button to import pages."),
                    target: () => pageTargets[0],
                },
                {
                    title: t("Add new folder"),
                    description: t("Use this button to create folders for your pages, and keep your environment organized"),
                    target: () => pageTargets[1],
                },
                {
                    title: t("Add new page"),
                    description: t("Start creating new content with this button"),
                    target: () => pageTargets[2],
                    onFinish: () => {
                        const pageDto: PageDto = {
                            id: undefined,
                            resolution: new Size(1920, 1080),
                            name: t("New Page"),
                            elements: [],
                            dataSourceIds: [],
                            duration: 10,
                            scrolling: true,
                            stepSize: 1,
                            scrollDuringPageSwap: true,
                            scrollTime: 10,
                            createdUtc: undefined,
                            lastUpdatedUtc: undefined
                        };
                        TdmApi.pages
                            .post(pageDto)
                            .then(result => location.href = `/${HttpConnection.environmentPath}/pages/${result.id}`);
                    },
                    nextButtonProps: {
                        children: t("Create a page"),
                    }
                }
            ]
        );
    };

    const setDesignerSteps = () => {
        setSteps(
            [
                {
                    title: t("Edit name"),
                    description: t("Edit the name of your page"),
                    target: () => designerTargets[0],
                },
                {
                    title: t("Zoom buttons"),
                    description: t("Zoom your canvas in and out with this button, it is also possible to zoom using 'ctrl' + 'scroll'"),
                    target: () => designerTargets[1],
                },
                {
                    title: t("Undo/Redo"),
                    description: t("Undo and redo your changes while editing your content"),
                    target: () => designerTargets[2],
                },
                {
                    title: t("See a preview"),
                    description: t("See a preview of your content"),
                    target: () => designerTargets[3],
                },
                {
                    title: t("Play and pause"),
                    description: t("Use this button to pause and play content while editing your content"),
                    target: () => designerTargets[4],
                },
                {
                    title: t("Element types"),
                    description: t("All your element types are shown here. Select or drag one and start designing your content"),
                    target: () => designerTargets[5],
                },
                {
                    title: t("Document properties"),
                    description: t("Here it is possible to edit all the properties of this page, for example: Resolution, background, foreground and export your page to use it in other environments"),
                    target: () => designerTargets[6],
                },
                {
                    title: t("Layers"),
                    description: t("All your elements are displayed here, double click an element to rename one"),
                    target: () => designerTargets[7],
                    onFinish: () => location.href = `/${HttpConnection.environmentPath}/playlists`,
                    nextButtonProps: {
                        children: t("Go to playlists"),
                    }
                }
            ]
        );
    };


    const setPlaylistSteps = () => {
        setSteps(
            [
                {
                    title: t("Add new folder"),
                    description: t("Use this button to create folders for your playlists, and keep your environment organized"),
                    target: () => playlistTargets[0],
                },
                {
                    title: t("Add a playlist"),
                    description: t("Start creating a playlists"),
                    target: () => playlistTargets[1],
                    onFinish: () => {
                        const playlistDto: PlaylistDto = {
                            id: undefined,
                            createdUtc: undefined,
                            lastUpdatedUtc: undefined,
                            resolution: new Size(1920, 1080),
                            name: t("New Playlist"),
                            shuffle: false,
                            items: []
                        };
                        TdmApi.playlists
                            .post(playlistDto)
                            .then(result => location.href = `/${HttpConnection.environmentPath}/playlists/${result.id}`);
                    },
                    nextButtonProps: {
                        children: t("Create a playlist"),
                    }
                }
            ]
        );
    };

    const setPlaylistEditSteps = () => {
        setSteps(
            [
                {
                    title: t("Edit name"),
                    description: t("Edit the name of your playlist"),
                    target: () => playlistEditTargets[0],
                },
                {
                    title: t("Playlist"),
                    description: t("See all the content that is added to your playlist"),
                    target: () => playlistEditTargets[1],
                },
                {
                    title: t("Menu"),
                    description: t("Choose the content type to add to your playlist"),
                    target: () => playlistEditTargets[2],
                },
                {
                    title: t("Content"),
                    description: t("Drag pages, playlists, media items to your playlist"),
                    target: () => playlistEditTargets[3],
                    onFinish: () => location.href = `/${HttpConnection.environmentPath}/media`,
                    nextButtonProps: {
                        children: t("Go to media"),
                    }
                }
            ]
        );
    };

    const setMediaBrowserSteps = () => {
        setSteps(
            [
                {
                    title: t("Add storage provider"),
                    description: t("Connect your dropbox, or onedrive to directly show content from these providers"),
                    target: () => mediaBrowserTargets[0],
                },
                {
                    title: t("Storage providers"),
                    description: t("See all the storage providers that are added to your environment"),
                    target: () => mediaBrowserTargets[1],
                },
                {
                    title: t("Folders"),
                    description: t("Navigate trough your connected storage provider"),
                    target: () => mediaBrowserTargets[2],
                },
                {
                    title: t("Files"),
                    description: t("See all the files that are present in the current folder"),
                    target: () => mediaBrowserTargets[3],
                },
                {
                    title: t("Upload files"),
                    description: t("Upload files to the storage provider"),
                    target: () => mediaBrowserTargets[4],
                },
                {
                    title: t("Details"),
                    description: t("See the details of a selected file"),
                    target: () => mediaBrowserTargets[5],
                    onFinish: () => location.href = `/${HttpConnection.environmentPath}/schedules`,
                    nextButtonProps: {
                        children: t("Go to schedules"),
                    }
                }
            ]
        );
    };

    const setScheduleIndexSteps = () => {
        setSteps(
            [
                {
                    title: t("Schedules list"),
                    description: t("See the list with created schedules"),
                    target: () => scheduleIndexTargets[0],
                },
                {
                    title: t("Schedules overview"),
                    description: t("See all the schedules in one overview"),
                    target: () => scheduleIndexTargets[1],
                },
                {
                    title: t("Create schedule"),
                    description: t("Create new Schedule"),
                    target: () => scheduleIndexTargets[2],
                    onFinish: () => {
                        const scheduleDto: ScheduleDto = {
                            name: "New Schedule",
                            actions: [],
                            activePeriods: [],
                            state: undefined,
                            enabled: false,
                            exceptions: [],
                            targets: [],
                        };
                        TdmApi.schedules.post(scheduleDto).then((result) =>{
                            location.href = `/${HttpConnection.environmentPath}/schedules/${result.id}`;
                        });
                        
                    },
                    nextButtonProps: {
                        children: t("Create a schedule"),
                    }
                }
            ]
        );
    };

    const setScheduleEditSteps = () => {
        setSteps(
            [
                {
                    title: t("Active periods"),
                    description: t("Edit when the schedule should be active"),
                    target: () => scheduleEditTargets[0],
                },
                {
                    title: t("State"),
                    description: t("Tell which state the players need to have when the schedule is active"),
                    target: () => scheduleEditTargets[1],
                },
                {
                    title: t("Targets"),
                    description: t("Select the players or player groups where this schedule needs to be assigned to"),
                    target: () => scheduleEditTargets[2],
                },
                {
                    title: t("Schedule overview"),
                    description: t("See an overview of when this schedule is active"),
                    target: () => scheduleEditTargets[3],
                    onFinish: () => {
                        location.href = `/${HttpConnection.environmentPath}/dataSources`;
                    },
                    nextButtonProps: {
                        children: t("Go to data sources"),
                    }
                }
            ]
        );
    };

    const setDataSourceIndexSteps = () => {
        setSteps(
            [
                {
                    title: t("Data sources"),
                    description: t("See the list with data sources you have created"),
                    target: () => dataSourceIndexTargets[0],
                },
                {
                    title: t("Create a data source"),
                    description: t("Choose a datasource type you want to create and create that data source"),
                    target: () => dataSourceIndexTargets[1],
                }
            ]
        );
    };
    
    
    const contextValue: TourType = {
        startTour: setOpen,
        setDashboardTargets: setDashboardTargets,
        setSearchTarget: setSearchTarget,
        setAccountTarget: setAccountTarget,
        setPlayerTargets: setPlayerTargets,
        setPageTargets: setPageTargets,
        setDesignerTargets: setDesignerTargets,
        setPlaylistTargets: setPlaylistTargets,
        setPlaylistEditTargets: setPlaylistEditTargets,
        setMediaBrowserTargets: setMediaBrowserTargets,
        setScheduleIndexTargets: setScheduleIndexTargets,
        setScheduleEditTargets: setScheduleEditTargets,
        setDataSourceIndexTargets: setDataSourceIndexTargets,
        isShowingTour: open
    };
    
    return (
        <TourContext.Provider value={contextValue}>
            {children}
            <Tour open={open} onClose={() =>setOpen(false)} steps={steps}/>
        </TourContext.Provider>);
};

export default TourContextProvider;

const useTour = () => useContext(TourContext);

export {useTour};