import {HttpConnection} from "../Designer/RequestCreator";
import {JsonApiController} from "./JsonApiController";
import {PowerPointMediaItemDto} from "shared/Generated/Dto/MediaItems/PowerPointMediaItemDto";


export class MediaApiController<MediaItemDto> extends JsonApiController<MediaItemDto> {
  constructor(path) {
    super(path);
  }

  public async getFromStorageContent(storageProviderId: string, contentId: string): Promise<MediaItemDto> {
    return await HttpConnection.getAsync(contentId, `${this.route}/getFromStorageContent/${storageProviderId}`) as MediaItemDto;
  }

  public async convertPowerPointToImages(storageProviderId: string, contentId: string): Promise<PowerPointMediaItemDto> {
    return await HttpConnection.getAsync(contentId, `${this.route}/convertPowerPointToImages/${storageProviderId}`) as PowerPointMediaItemDto;
  }
}