import {EnvironmentDto} from "shared/Generated/Dto/EnvironmentDto";
import {HttpConnection} from "../Designer/RequestCreator";
import {EnvironmentValidationDto} from "shared/Generated/Dto/EnvironmentValidationDto";
import {MqttCredentialsDto} from "shared/Generated/Dto/MqttCredentialsDto";
import {CustomerDto} from "shared/Generated/Dto/Licenses/CustomerDto";

export class EnvironmentApiController {
  private readonly route = `environments/json`;

  public getAll(): Promise<EnvironmentDto[]> {
    return HttpConnection.getAsync("", this.route, false) as Promise<EnvironmentDto[]>;
  }

  public get(id: string): Promise<EnvironmentDto> {
    return HttpConnection.getAsync(id, this.route, false) as Promise<EnvironmentDto>;
  }

  public getByPath(environmentPath: string): Promise<EnvironmentDto> {
    return HttpConnection.getAsync(environmentPath, `${this.route}/getByPath`, false) as Promise<EnvironmentDto>;
  }

  public post(dto: EnvironmentDto): Promise<EnvironmentDto> {
    return HttpConnection.postAsync(dto, this.route, false) as Promise<EnvironmentDto>;
  }

  public put(dto: EnvironmentDto, id: string): Promise<EnvironmentDto> {
    return HttpConnection.putAsync(dto, `${this.route}/${id}`, false) as Promise<EnvironmentDto>;
  }

  public validate(dto: EnvironmentDto): Promise<EnvironmentValidationDto> {
    return HttpConnection.postAsync(dto, `${this.route}/validate`, false) as Promise<EnvironmentValidationDto>;
  }

  public getDesignerCredentials(): Promise<MqttCredentialsDto> {
    return HttpConnection.getAsync(null, `${this.route}/GetDesignerCredentials`) as Promise<MqttCredentialsDto>;
  }

  public getCustomer(): Promise<CustomerDto> {
    return HttpConnection.getAsync(null, `${this.route}/GetCustomer`) as Promise<CustomerDto>;
  }

  public async join(invitationId: string): Promise<any> {
    return await HttpConnection.postAsync({}, `${this.route}/join/${invitationId}`, false);
  }

  public async getAvatar(environmentId: string) {
    return await HttpConnection.getAsync("", `environments/${environmentId}/avatar`, false);
  }

  public async uploadAvatar(environmentId: string, formData: FormData): Promise<any> {

    return fetch(`/environments/${environmentId}/uploadAvatar`, {
      method: 'POST',
      body: formData
    });
  }

  public search(query: string): Promise<EnvironmentDto[]> {
    return HttpConnection.getAsync(query, `${this.route}/search`, false);
  }
  
  public async getNotifications(environmentId: string): Promise<any> {
    return HttpConnection.getAsync(null, `${this.route}/${environmentId}/notifications`, false);
  }
}