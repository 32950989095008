import {HttpConnection} from "../Designer/RequestCreator";
import {FileDto} from "../Dto/FileDto";
import {FolderDto} from "../Dto/FolderDto";
import {Cookie} from "../Util/Cookie";


export class FileSystemApiController {
  protected readonly route: string;


  constructor() {
    this.route = "fileSystem";
  }

  public async createFolder(storageProviderId: string, folderName: string): Promise<string> {
    return await HttpConnection.postAsync(null, `${this.route}/${storageProviderId}/Folder?storageProviderId=${storageProviderId}&folderName=${folderName}`);
  }

  public async getBreadcrumb(storageProviderId: string, fileUri: string): Promise<any> {
    return await HttpConnection.getAsync(null, `${this.route}/${storageProviderId}/Breadcrumb?storageProviderId=${storageProviderId}&fileUri=${fileUri}`);
  }

  public async search(storageProviderId: string, query: string): Promise<FolderDto> {
    return await HttpConnection.getAsync(null, `${this.route}/${storageProviderId}/Search?storageProviderId=${storageProviderId}&searchValue=${query}`);
  }

  public async getFolderContent(storageProviderId: string, folderId: string): Promise<FolderDto> {
    return await HttpConnection.getAsync(null, `${this.route}/${storageProviderId}/FolderContent?storageProviderId=${storageProviderId}&folderName=${encodeURIComponent(folderId)}`);
  }

  public async getFileContent(storageProviderId: string, fileUri: string): Promise<FileDto> {
    return await HttpConnection.getAsync(null, `${this.route}/${storageProviderId}/FileContent?fileUri=${encodeURIComponent(fileUri)}`);
  }

  public async getFile(storageProviderId: string, fileUri: string): Promise<string> {
    return await HttpConnection.getAsync(null, `${this.route}/${storageProviderId}/File?fileUri=${encodeURIComponent(fileUri)}`);
  }

  public async uploadFile(storageProviderId: string, formData: FormData): Promise<any> {
    return fetch(`${HttpConnection.hostOverride}/${HttpConnection.environmentPath}/${this.route}/${storageProviderId}/File?storageProviderId=${storageProviderId}`, {
      method: 'POST',
      body: formData
    });
  }

  public async uploadFileToDefault(formData: FormData): Promise<any> {
    return fetch(`/${HttpConnection.environmentPath}/${this.route}/File`, {
      method: 'POST',
      body: formData
    });
  }

  public async getThumbnail(storageProviderId: string, id: string): Promise<string> {
    return await HttpConnection.getAsync(id, `${this.route}/${storageProviderId}/getThumbnail`) as string;
  }

  public async copyFile(storageProviderId: string, fileUri: string): Promise<FileDto> {
    return await HttpConnection.getAsync(fileUri, `${this.route}/${storageProviderId}/copyFile`) as FileDto;
  }

  public async moveFile(storageProviderId: string, fileUri: string, destinationFileUri: string): Promise<FileDto> {
    return await HttpConnection.postAsync({
      fileUri: fileUri,
      destinationFileUri: destinationFileUri
    }, `${this.route}/${storageProviderId}/moveFile`);
  }

  public async moveFolder(storageProviderId: string, fileUri: string, destinationFileUri: string): Promise<FolderDto> {
    return await HttpConnection.postAsync({
      fileUri: fileUri,
      destinationFileUri: destinationFileUri
    }, `${this.route}/${storageProviderId}/moveFolder`);
  }

  public async renameFile(storageProviderId: string, fileUri: string, newName: string): Promise<FileDto> {
    return await HttpConnection.postAsync({
      fileUri: fileUri,
      name: newName
    }, `${this.route}/${storageProviderId}/renameFile`) as FileDto;
  }

  public async renameFolder(storageProviderId: string, fileUri: string, newName: string): Promise<FolderDto> {
    return await HttpConnection.postAsync({
      fileUri: fileUri,
      name: newName
    }, `${this.route}/${storageProviderId}/renameFolder`) as FolderDto;
  }

  public async deleteFile(storageProviderId: string, fileUri: string): Promise<FolderDto | FileDto> {
    return await HttpConnection.deleteAsync(fileUri, `${this.route}/${storageProviderId}/File`, true) as FolderDto;
  }

  public async deleteFolder(storageProviderId: string, folderUri: string): Promise<FolderDto | FileDto> {
    return await HttpConnection.deleteAsync(folderUri, `${this.route}/${storageProviderId}/Folder`, true) as FileDto;
  }
}