import {Cookie} from "../Util/Cookie";

export class HttpConnection {
  public static environmentPath: string = Cookie.getCookie("lastVisitedEnvironmentPath");
  public static hostOverride: string = Cookie.getCookie("hostOverride") ?? "";
  public static isTeos: boolean = HttpConnection.hostOverride !== "";
  
  static createUrl(id: string | null, route: string, withEnvironment = true) {
    return this.hostOverride + (withEnvironment ? `/${this.environmentPath}` : '') + `/${route}`;
  }

  static postAsync(object: any, route: string, withEnvironment = true) {
    const fullRoute = (withEnvironment ? `/${this.environmentPath}` : '') + `/${route}`;
    return this.send(object, this.hostOverride + fullRoute, "POST");
  }

  static getAsync(id: string | null, route: string, withEnvironment = true) {
    const fullRoute = (withEnvironment ? `/${this.environmentPath}` : '') + `/${route}`;
    return this.sendId(id, this.hostOverride + fullRoute, "GET");
  }

  static putAsync(object: any, route: string, withEnvironment = true) {
    const fullRoute = (withEnvironment ? `/${this.environmentPath}` : '') + `/${route}`;
    return this.send(object, this.hostOverride + fullRoute, "PUT");
  }

  static deleteAsync(id: string, route: string, withEnvironment = true) {
    const fullRoute = (withEnvironment ? `/${this.environmentPath}` : '') + `/${route}`;
    return this.sendId(id, this.hostOverride + fullRoute, "DELETE");
  }

  private static async send(object: any, route: string, method: string) {
    return new Promise<any>((resolve, reject) => {
      fetch(`${route}`, {
        method: method,
        body: JSON.stringify(object),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }).then(async response => {
        if (response.ok) {
          try {
            resolve(await response.json());
          } catch {
            resolve(null);
          }
        } else {
          console.error(response);
          try {
            reject(await response.json());
          } catch {
            reject(null);
          }
        }
      }).catch(error => {
        reject(error);
      });
    });
  }

  static async sendId(id: string | null, route: string, method: string) {
    try {
      const url = `${id != null ? `${route}/${encodeURIComponent(id)}` : route}`;
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1)
        return response.json();

      return null;

    } catch (error) {
      if (error instanceof Error) {
        console.log('error message: ', error.message);
        throw error.message;
      } else {
        console.log('unexpected error: ', error);
        throw 'An unexpected error occurred';
      }
    }
  }
}