import React, {useEffect, useState} from 'react';
import {Defaults} from "../../Tdm/Defaults";
import Skeleton from "react-loading-skeleton";
import styles from "./ListGridItem.scss";
import {TdmApi} from "shared/Api/TdmApi";

interface props {
  alt: string
  id?: string
  lastUpdated: Date
  size?: ThumbnailSize
  className?: string
  fallBackImage?: string
  style?: React.CSSProperties,
  noLoader?: boolean,
  loaderClass?: string
}

const Thumbnail: React.FC<props> = ({
                                      alt,
                                      id = null,
                                      className,
                                      lastUpdated,
                                      fallBackImage,
                                      style,
                                      noLoader = false,
                                      loaderClass = ""
                                    }) => {
  let lastUpdatedTime = lastUpdated ? new Date(lastUpdated).getTime() : new Date().getTime();
  const [imageSrc, setImageSrc] = useState<string>(id !== null ? TdmApi.content.thumbnailPath(id, lastUpdatedTime) : null);
  const [thumbnailLoaded, setThumbnailLoaded] = useState<boolean>(false);
  const [retries, setRetries] = useState<number>(0);
  
  let retryTimeout: NodeJS.Timeout | null = null;
  const maxRetries = 4;
  const retryInterval = 2000;

  useEffect(() => {
    if(id !== null) {
      retryTimeout && clearTimeout(retryTimeout);
      setRetries(0);
      setImageSrc(TdmApi.content.thumbnailPath(id, lastUpdatedTime));
    }
    else {
      setImageSrc(fallBackImage ?? Defaults.IMAGE_URL);
    }
  }, [id]);
  
  function onLoaded(event: React.SyntheticEvent<HTMLImageElement>){
    if(imageSrc !== fallBackImage ?? Defaults.IMAGE_URL)
      setThumbnailLoaded(true);
  }
  
  function onError (error: React.SyntheticEvent<HTMLImageElement>) {
    setImageSrc(fallBackImage ?? Defaults.IMAGE_URL);
    if (retries >= maxRetries)
      return
    
    retryTimeout = setTimeout(() => {
      setRetries(retries + 1);
      setImageSrc(TdmApi.content.thumbnailPath(id, lastUpdatedTime));
    }, retryInterval);
  }

  return (
    <>
      <img src={imageSrc} alt={alt} onLoad={onLoaded} onError={onError} className={className} draggable={false} style={style}/>
      {!noLoader && !thumbnailLoaded && retries < maxRetries ?
        <Skeleton className={`${styles.skeletonImage} ${loaderClass}`}/> : null}
    </>
  );
};

export default Thumbnail;

export enum ThumbnailSize {
  Small,
  Medium,
  Large,
  Big
}